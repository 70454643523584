import { concatRespectingCase } from "../utils/stringUtils.js";

import { generateColumnIdForField } from "./exploreFieldUtils.js";
import { hqlAggToPivotAgg } from "./explorePivotUtils.js";
import { ExploreField, ExploreSpec } from "./types.js";

// TODO - deprecate this utility and use viz specific logic to determine the field names
export function exploreTableTemporaryFieldName(field: ExploreField): string {
  let value = generateColumnIdForField(field);

  if (field.aggregation != null) {
    value = concatRespectingCase(
      field.value,
      // use pivot agg to stay in sync with the pivot column name
      `_${hqlAggToPivotAgg(field.aggregation)}`,
      "append",
    );
  }
  if (field.truncUnit != null) {
    value = concatRespectingCase(field.value, `_${field.truncUnit}`, "append");
  }
  return value;
}

export function exploreNestedTableEnabled({
  spec,
}: {
  spec: ExploreSpec;
}): boolean {
  if (spec.chartConfig.series.length > 1) {
    // multi-series not supported
    return false;
  } else if (spec.visualizationType === "pivot-table") {
    // no gotchas in pivot table right now, should work
    return true;
  } else if (spec.chartConfig.series[0]?.type === "histogram") {
    // cant bin
    return false;
  }
  return true;
}
