import { Literal, Static, Union } from "runtypes";

import { ExtractAtomicOperationFromDefinition } from "../../atomic-operations/AtomicOperationDefinition";
import { HexId, MagicEventId } from "../../idTypeBrands";
import {
  MagicAcceptType,
  MagicCellChainTemplateType,
  MagicCellPayload,
  MagicCellRunResult,
  MagicClientReferenceInfo,
  MagicEventFailureReason,
  MagicEventStatus,
  MagicRequestMode,
} from "../../MagicTypes";
import { getNormalEnum } from "../../runtypeEnums.js";
import { SemanticCap } from "../../semanticCaps.js";
import { typedObjectKeys } from "../../utils/typedObjects.js";
import { createHexVersionAtomicOperationDefinition } from "../HexVersionAtomicOperationDefinition";

import { CREATE_MAGIC_EVENT } from "./createMagicEventOperation";

export const MagicEventDerivedFieldsLiteral = Union(
  Literal("requestMode"),
  Literal("chainTemplateType"),
  Literal("relatedProjectIds"),
  Literal("topReferenceInfo"),
  Literal("chainSummary"),
  Literal("summary"),
);

export type MagicEventDerivedFields = Static<
  typeof MagicEventDerivedFieldsLiteral
>;
export const MagicEventDerivedFields = getNormalEnum(
  MagicEventDerivedFieldsLiteral,
);

export interface MagicEventUpdatableFields {
  status: MagicEventStatus | null;
  accepted: boolean | null;
  nextEventId: MagicEventId | null;
  prevEventId: MagicEventId | null;
  chainTemplateType: MagicCellChainTemplateType | null;
  requestMode: MagicRequestMode | null;
  unsupportedResponse: boolean;
  cellRunResult: MagicCellRunResult | null;
  userFacingResult: string | null;
  acceptType: MagicAcceptType | null;
  failureReason: MagicEventFailureReason | null;
  result: string | null;
  autoTriggered: boolean;
  cellPayload: MagicCellPayload | null;
  relatedProjectIds: readonly HexId[] | null;
  topReferenceInfo: readonly MagicClientReferenceInfo[] | null;
  chainSummary: string | null;
  summary: string | null;
}

const _UpdateMagicEventSafeFields: {
  [key in keyof MagicEventUpdatableFields]: "";
} = {
  status: "",
  accepted: "",
  nextEventId: "",
  prevEventId: "",
  chainTemplateType: "",
  requestMode: "",
  unsupportedResponse: "",
  cellRunResult: "",
  userFacingResult: "",
  acceptType: "",
  failureReason: "",
  result: "",
  autoTriggered: "",
  cellPayload: "",
  relatedProjectIds: "",
  topReferenceInfo: "",
  chainSummary: "",
  summary: "",
};

export const UpdateMagicEventSafeFields = typedObjectKeys(
  _UpdateMagicEventSafeFields,
);

const UPDATE_MAGIC_EVENT_TYPE = "UPDATE_MAGIC_EVENT" as const;
export const UPDATE_MAGIC_EVENT = createHexVersionAtomicOperationDefinition({
  type: UPDATE_MAGIC_EVENT_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
  },
  writeAuth: {
    kind: "hasSemanticCapOnIdArg",
    idArg: "magicEventId",
    idType: "MagicEvent",
    cap: SemanticCap.EDIT_MAGIC_EVENT,
  },
  logSafe: ["magicEventId", "key"],
  conflictId: (op) =>
    `${UPDATE_MAGIC_EVENT_TYPE}-${op.payload.magicEventId}-${op.payload.key}`,
  creationId: (op) => `${CREATE_MAGIC_EVENT.type}-${op.payload.magicEventId}`,
  excludeFromHistory: true,
  alwaysSkipUndoBuffer: true,
  create: <K extends keyof MagicEventUpdatableFields>(
    magicEventId: MagicEventId,
    key: K,
    value: MagicEventUpdatableFields[K],
  ) => ({
    type: UPDATE_MAGIC_EVENT_TYPE,
    payload: {
      magicEventId,
      key,
      value,
    },
  }),
});

export type UPDATE_MAGIC_EVENT = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_MAGIC_EVENT
>;
